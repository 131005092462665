import React, { useEffect, useState } from "react"
import LoginComponent from "../components/LoginComponents/Login"
import LayoutProvider from "../../src/components/Layout"
import NOIEComponent from "../Utils/NoIExplorer"

const IndexPage = ({ location }) => {
  const [Explorer, setExplorer] = useState(false)
  useEffect(() => {
    var ua = window.navigator.userAgent;
    var isIE = /MSIE|Trident/.test(ua);

    if(isIE){
      setExplorer(true)
    }
  }, [])
  return (
    <>
        {Explorer === false ? (
        <LayoutProvider location={location}>
          <LoginComponent />
        </LayoutProvider>
      ) : (
        <NOIEComponent />
      )}
    </>
  )
}

export default IndexPage
