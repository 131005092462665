import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import logo from "../images/unnamed.jpg"
import chrome from "../images/exploradores/chrome.png"
import firefox from "../images/exploradores/firefox.png"
import opera from "../images/exploradores/opera.png"
import safari from "../images/exploradores/safari.png"
import edge from "../images/exploradores/edge.png"

const NOIEComponent = () => {
  const LinkDescarga = link => {
    window.open(link, "_blank")
  }
  return (
    <div className="contenedor mt-4 bg-noie">
      <Row className="my-3 ">
        <Col xs="12" xl="12" className="text-center font-weight-bold">
          <img
            src={logo}
            className="mb-4 d-flex mx-auto"
            alt="Logo Superintendencia De Salud"
          />
          <p className="font-weight-bold text-center my-3">
            Estimado/a Usuario/a
          </p>

          <p className="font-weight-bold text-center my-3">
            Estás ingresando a la Superintendencia de Salud.
          </p>

          <p className="font-weight-bold text-center my-3 text-justify">
            Para un mejor desempeño de la plataforma, te sugerimos utilizar
            cualquiera de los siguientes navegadores en tu dispositivo
            (computador, notebook, tablet, teléfono, etc.):
          </p>
        </Col>

        <Col xs="6" xl="3" className="my-2  text-center font-weight-bold">
          <p>Mozilla Firefox</p>
          <img
            src={firefox}
            className="w-50"
            alt="Imagen firefox Superintendencia De Salud "
          />
          <button
            type="button"
            className="btn btn-login-ss mx-auto mt-2"
            onClick={() =>
              LinkDescarga("https://www.mozilla.org/es-CL/firefox/new/")
            }
          >
            Descargar Aquí
          </button>
        </Col>

        <Col xs="6" xl="3" className="my-2 text-center font-weight-bold">
          <p>Google Chrome</p>
          <img
            src={chrome}
            className="w-75"
            alt="Imagen chrome Superintendencia De Salud"
          />
          <button
            type="button"
            className="btn btn-login-ss mx-auto mt-2"
            onClick={() =>
              LinkDescarga(
                "https://www.google.com/intl/es/chrome/?brand=CHBD&brand=UUXU&gclid=Cj0KCQiAyJOBBhDCARIsAJG2h5cWpO_XufLPc5kaG8hCDFLpRfZbSu_AdBjRKX7o4xNMfka2rYjthZAaAmQeEALw_wcB&gclsrc=aw.ds"
              )
            }
          >
            Descargar Aquí
          </button>
        </Col>
        <Col xs="6" xl="3" className="my-2  text-center font-weight-bold">
          <p>Opera</p>
          <img
            src={opera}
            className="w-50"
            alt="Imagen opera Superintendencia De Salud "
          />
          <button
            type="button"
            className="btn btn-login-ss mx-auto mt-2"
            onClick={() => LinkDescarga("https://www.opera.com/es")}
          >
            Descargar Aquí
          </button>
        </Col>
        <Col xs="6" xl="3" className="my-2  text-center font-weight-bold">
          <p>Safari</p>
          <img
            src={safari}
            className="w-50"
            alt="Imagen safari Superintendencia De Salud "
          />
          <button
            type="button"
            className="btn btn-login-ss mx-auto mt-2"
            onClick={() => LinkDescarga("https://www.apple.com/cl/safari/")}
          >
            Descargar Aquí
          </button>
        </Col>

        <Col xs="6" xl="3" className="my-2  text-center font-weight-bold">
          <p>Microsoft Explorer Edge</p>
          <img
            src={edge}
            className="w-50"
            alt="Imagen Edge Superintendencia De Salud "
          />
          <button
            type="button"
            className="btn btn-login-ss mx-auto mt-2"
            onClick={() =>
              LinkDescarga(
                "https://www.microsoft.com/es-es/edge/business/download"
              )
            }
          >
            Descargar Aquí
          </button>
        </Col>
      </Row>
    </div>
  )
}

export default NOIEComponent
